var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('b-tabs',{on:{"change":_vm.changeActiveTab},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.tabs),function(tab){return _c('b-tab-item',{key:tab,attrs:{"label":_vm.labels(tab)}},[_c('b-table',{attrs:{"loading":_vm.loading,"paginated":_vm.paginated,"per-page":_vm.perPage,"striped":true,"hoverable":true,"default-sort":"starts","data":_vm.dataSorted[tab]},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-table-column',{attrs:{"label":_vm.$getTranslation('surveys.attributes.name'),"field":"survey","sortable":""}},[(_vm.ability.can('read', 'survinst', props.row.slug))?_c('router-link',{attrs:{"to":{
                name: 'surveys.detail',
                params: { slug: props.row.slug },
              }}},[_vm._v(" "+_vm._s(props.row.survey_title)+" ")]):_c('div',[_vm._v(_vm._s(props.row.survey_title))])],1),(_vm.canSee)?_c('b-table-column',{attrs:{"label":"Organization","field":"organization","sortable":""}},[_vm._v(" "+_vm._s(props.row.org_name)+" ")]):_vm._e(),(_vm.canSee)?_c('b-table-column',{attrs:{"label":"Division","field":"division","sortable":""}},[_vm._v(" "+_vm._s(props.row.div_name)+" ")]):_vm._e(),_c('b-table-column',{attrs:{"label":_vm.$getTranslation('surveys.attributes.starts'),"field":"starts","sortable":""}},[_vm._v(" "+_vm._s(props.row.starts)+" ")]),_c('b-table-column',{attrs:{"label":_vm.$getTranslation('surveys.attributes.ends'),"field":"ends","sortable":""}},[_vm._v(" "+_vm._s(props.row.expires)+" ")]),(_vm.canSee)?_c('b-table-column',{staticClass:"is-progress-col",attrs:{"label":"Returns","field":"sessions","sortable":""}},[_c('ReturnRateBar',{attrs:{"sessions-finished":props.row.finished,"sessions-total":props.row.sessions_total}})],1):_vm._e(),_c('b-table-column',{staticClass:"is-actions-cell",attrs:{"custom-key":"actions","width":"160"}},[_c('div',{staticClass:"buttons is-right"},[(_vm.ability.can('read', 'survinst', props.row.slug))?_c('router-link',{staticClass:"button is-small is-primary is-inline",attrs:{"to":{
                  name: 'surveys.detail',
                  params: { slug: props.row.slug },
                },"title":"Survey Details"}},[_c('b-icon',{attrs:{"icon":"arrow-right","size":"is-small"}})],1):_vm._e(),(_vm.isLoggedUser.roles.includes('participant'))?_c('router-link',{staticClass:"button is-small is-primary is-inline",attrs:{"to":{
                  name: 'users.edit',
                  params: { slug: _vm.isLoggedUser.slug },
                }}},[_c('b-icon',{attrs:{"icon":"arrow-right","size":"is-small"}})],1):_vm._e(),_c('socialShare',{attrs:{"socialTokenUrl":props.row.socialTokenUrl}})],1)])]}}],null,true)},[_c('section',{staticClass:"section",attrs:{"slot":"empty"},slot:"empty"},[_c('div',{staticClass:"content has-text-grey has-text-centered"},[(_vm.loading)?[_c('p',[_c('b-icon',{attrs:{"icon":"dots-horizontal","size":"is-large"}})],1),_c('p',[_vm._v(_vm._s(_vm.$getTranslation('general.loading')))])]:[_c('p',[_c('b-icon',{attrs:{"icon":"emoticon-sad","size":"is-large"}})],1),_c('p',[_vm._v(_vm._s(_vm.$getTranslation('general.0records')))])]],2)])])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }