<template>
    <b-dropdown aria-role="list">
      <template #trigger="{ active }">
          <b-button
            title="Share on social media"
            type="is-small is-light"
            :icon-right="active ? 'menu-up' : 'menu-down'">
            <i title="Share" class="mdi mdi-share-variant"></i>
          </b-button>
      </template>

      <b-dropdown-item aria-role="listitem">
        <b-button
          title="Share project link"
          class="is-small is-text ml-0 pl-0"
          :icon-left="'mdi mdi-share-variant'"
          @click.stop.prevent="
            copyURL(socialInviteUrl('link', socialTokenUrl))
          "
        >
          Share Link
        </b-button>
        <input
          type="hidden"
          id="project-link"
          :value="socialInviteUrl('link', socialTokenUrl)"
        />
      </b-dropdown-item>

      <b-dropdown-item aria-role="listitem">
        <ShareNetwork
          network="facebook"
          :url="socialInviteUrl('fb', socialTokenUrl)"
          title="Invitations for participants"
          description="We'd love to invite you to participate on owr new project"
          quote="This is a survey project"
        >
          <i title="Facebook" class="mdi mdi-facebook"></i>
          Facebook
        </ShareNetwork>
      </b-dropdown-item>

      <b-dropdown-item aria-role="listitem">
        <ShareNetwork
          network="twitter"
          :url="socialInviteUrl('tw', socialTokenUrl)"
          title="Invitations for participants"
          description="We'd love to invite you to participate on owr new project"
          quote="This is a survey project"
        >
          <i title="Twitter" class="mdi mdi-twitter"></i>
          Twitter
        </ShareNetwork>
      </b-dropdown-item>

      <!-- <b-dropdown-item aria-role="listitem">
        <ShareNetwork
          network="whatsapp"
          :url="socialInviteUrl('wa', socialTokenUrl)"
          title="Invitations for participants"
          description="We'd love to invite you to participate on owr new project"
          quote="This is a survey project"
          hashtags="vuejs,vite"
        >
          <i title="WhatsApp" class="mdi mdi-whatsapp"></i>
          WhatsApp
        </ShareNetwork>
      </b-dropdown-item> -->

  </b-dropdown>
</template>
<script>
export default {
  name: 'app-social-dropdown',
  props: {
    token: {
      default:null
    },
    socialTokenUrl: {
      default:null
    },
  },
  data () {
    return {
      //
    }
  },
  computed: {
    //
  },

  methods: {
    socialInviteUrl(media, socialTokenUrl) {
      return socialTokenUrl + '/' + media
    },

    copyURL(link) {
      let projectLinkToCopy = document.querySelector('#project-link')
      console.log(link, projectLinkToCopy)
      projectLinkToCopy.setAttribute('type', 'text')
      projectLinkToCopy.select()

      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successfully' : 'unsuccessfully';
        this.$toast.success('Project link was copied ' + msg);
      } catch (err) {
        this.$toast.danger('Oops, unable to copy');
      }

      /* unselect the range */
      projectLinkToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
  },

  created () {
    // console.log('share social created')
  }
}
</script>
