<template>
  <div>
    <tiles class="is-vertical">
      <template v-if="loading || !selectedSurvey">
        <card-widget
          class="tile is-child is-vertical" type="is-success" icon="chart-bar"
          suffix="%" label="Return Rate">
          <b-loading :active.sync="loading" :is-full-page="false" :can-cancel="true"/>
        </card-widget>
        <card-widget
          class="tile is-child is-vertical" type="is-dark" icon="rocket"
          suffix="%" label="Started">
          <b-loading :active.sync="loading" :is-full-page="false" :can-cancel="true"/>
        </card-widget>
        <card-widget
          class="tile is-child is-vertical" type="is-danger" icon="alert-outline"
          suffix="%" label="Not Started">
          <b-loading :active.sync="loading" :is-full-page="false" :can-cancel="true"/>
        </card-widget>
      </template>
      <template v-else>
        <card-widget
          class="tile is-child is-vertical" type="is-success" icon="chart-bar"
          :number="round(
                      (selectedSurvey.finished * 100) /
                        selectedSurvey.sessions_total,2)"
          suffix="%" :label="$t('surveys.returnRateCards.returnRate')">
          <div slot="subnumber">
            {{ selectedSurvey.sessions_finished }}
            <span class="has-text-grey-light">/ {{ selectedSurvey.sessions_total }}</span>
          </div>
        </card-widget>
        <card-widget
          class="tile is-child is-vertical" type="is-dark" icon="rocket"
          :number="round(
                      (selectedSurvey.inprogress * 100) /
                        selectedSurvey.sessions_total, 2)"
          suffix="%" :label="$t('surveys.returnRateCards.started')">
          <div slot="subnumber">
            {{ selectedSurvey.inprogress }}
            <span class="has-text-grey-light">/ {{ selectedSurvey.sessions_total }}</span>
          </div>
        </card-widget>
        <card-widget
          class="tile is-child is-vertical" type="is-danger" icon="alert-outline"
          :number="round(
                      (selectedSurvey.pending * 100) /
                        selectedSurvey.sessions_total,2)"
          suffix="%" :label="$t('surveys.returnRateCards.notStarted')">
          <div slot="subnumber">
            {{ selectedSurvey.pending }}
            <span class="has-text-grey-light">/ {{ selectedSurvey.sessions_total }}</span>
          </div>
        </card-widget>
      </template>
    </tiles>
  </div>
</template>

<script>
import CardWidget from '@/components/CardWidget'
import Tiles from '@/components/Tiles'

export default {
  name: 'ReturnRateCards',
  components: { CardWidget, Tiles },
  props: {
    selectedSurvey: {
      type: Object,
      default: {}
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    round (value, exp) {
      if (typeof exp === 'undefined' || +exp === 0) return Math.round(value)
      value = +value
      exp = +exp
      if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
        return NaN
      }
      // Shift
      value = value.toString().split('e')
      value = Math.round(
        +(value[0] + 'e' + (value[1] ? +value[1] + exp : exp))
      )
      // Shift back
      value = value.toString().split('e')
      return +(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp))
    }
  }
}
</script>

<style scoped>

</style>
