<template>
  <div>
    <hero-bar :has-right-visible="false">
      Dashboard
    </hero-bar>

    <section class="section" v-if="isAdminOwner">
      <div class="content" v-if="!loading">
        <surveys-dropdown
          :loading="loading"
          :surveys="activeSurveys"
          :selectedSurvey="displaySurvey"
          @change-selected-survey="changeSurvey"
        />
      </div>

      <div class="columns">
        <div class="column is-narrow">
          <rate-cards :selected-survey="displaySurvey" :loading="loading" />
        </div>
        <div class="column">
          <card-component
            :title="$t('surveys.title.surveyParticipation')"
            @header-icon-click="updateReturnChart"
            icon="chart-bar"
          >
            <template v-if="loading">
              <div class="chart-area">
                <b-loading
                  :active.sync="loading"
                  :is-full-page="false"
                  :can-cancel="true"
                />
              </div>
            </template>
            <template v-else>
              <div v-if="returnChart.chartData" class="chart-area">
                <line-chart
                  style="height: 100%"
                  ref="bigChart"
                  chart-id="big-line-chart"
                  :chart-data="returnChart.chartData"
                  :extra-options="returnChart.extraOptions"
                />
              </div>
            </template>
          </card-component>
        </div>
      </div>
    </section>

    <section class="section simple has-background-white">
      {{ getTabs }}
      <div class="content">
        <h1 class="title">{{ $t("surveys.title.surveyOverview") }}</h1>
        <SurveyList
          v-if="items !== null"
          :tabs="tabOrder"
          :paginated="paginated"
          :per-page="perPage"
          :surveys="activeSurveys"
          :canSee="isAdminOwner"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { ApiStats, apiUrl } from '@/api-routes.js'
import * as chartConfig from '@/components/Charts/chart.config'
import ListTemplateMixin from '@/mixins/ListTemplateMixin'
import HeroBar from '@/components/HeroBar'
import LineChart from '@/components/Charts/LineChart'
import CardComponent from '@/components/CardComponent'
import SurveyList from '@/components/surveys/List'
import SurveysDropdown from '@/components/surveys/Dropdown'
import RateCards from '@/components/surveys/rate/Cards'

export default {
  name: 'dashboard',
  mixins: [ListTemplateMixin],
  components: {
    RateCards,
    SurveyList,
    SurveysDropdown,
    HeroBar,
    LineChart,
    CardComponent
  },

  data() {
    return {
      activeTab: 0,
      isAdminOwner: true,
      // paginated: true,
      // perPage: 10,
      tabOrder: ['active', 'upcoming', 'finished'],
      returnChart: {
        chartData: null,
        extraOptions: chartConfig.chartOptionsSurveySessions
      },
      activeSurveys: [],
      displaySurvey: null,
      endpoint: ApiStats
    }
  },

  computed: {
    getTabs () {
      if(!this.isAdminOwner) {
        // this.tabOrder = ['active', 'finished']
      } else {

      }
    }
  },

  methods: {
    ...mapActions([
      'setLoading',
      'setError',
      'getSurveySessions'
    ]),

    canSee () {
      this.isAdminOwner = this.isLoggedUser.roles === 'admin' || this.isLoggedUser.roles === 'owner'
    },

    setActiveSurveys() {
      if (this.items.length) {
        this.activeSurveys = this.items.filter(s => {
          // survey_status undefined -> kick it out
          if (!s.survey_status) {
            return false
          }
          return s.survey_status === 'active'
        })
      }
      // not needed as it is global var
      // return this.activeSurveys
    },

    changeSurvey(survey) {
      // Gets called on page load and when child components, e.g. SurveyDropdown
      // emit a 'change-selected-survey' event
      if (survey) {
        this.setLoading(true)
        this.displaySurvey = survey
        let slug = survey.slug
        let payload = ApiStats + '/surveySessionsOverTime/' + slug
        this.getSurveySessions(payload).then(res => {
          this.displaySurvey.surveySessions = res
          this.updateReturnChart()
          this.setLoading(false)
        })
      } else {
        console.log('changeSurvey called with illegal arguments')
      }
    },

    updateReturnChart() {
      // (TS) caching format
      let dateFormat = 'YYYY-MM-DD'
      let labels = []
      let label_map = {}
      let data = []
      let i = 0
      // build up the x-axis of dates the number of sessions are
      // aggregated over
      let curDate = this.displaySurvey.starts_at
        ? moment(this.displaySurvey.starts_at)
        : setError(true)
      let lastDate = this.displaySurvey.expires_at
        ? moment(this.displaySurvey.expires_at)
        : moment()
      // chart should only display data up to the last day of survey or today, whichever comes first
      while (!curDate.isAfter(lastDate) && !curDate.isAfter(moment())) {
        labels.push(curDate.format(dateFormat))
        data.push(0)
        label_map[curDate.format(dateFormat)] = i++
        curDate = curDate.add(1, 'days')
      }

      // TS: added if there is no surveySessions data to display
      let  surveySessions = this.displaySurvey.surveySessions
      if(surveySessions) {
        surveySessions.forEach(sess => {
          if (sess.status === 'finished') {
            let setDateFormat = moment(sess.finished_at).format(dateFormat)
            // look up which index a date has in the label_map, increment the value there by one
            data[label_map[setDateFormat]] += 1
          }
        })
      }

      this.returnChart = {
        chartData: {
          datasets: [
            {
              fill: false,
              borderColor: chartConfig.chartColors.default.success,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: chartConfig.chartColors.default.success,
              pointBorderColor: 'rgba(164,100,77,0)',
              pointHoverBackgroundColor:
                chartConfig.chartColors.default.success,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: data
            }
          ],
          labels: labels
        },
        extraOptions: chartConfig.chartOptionsSurveySessions
      }
    }
  },

  async created() {
    // this.success('woooow')
    this.canSee()
    await this.fetchItems(this.endpoint).catch(error => {
      this.$toast.danger('Error loading data')
      console.log('Error while fetching Stats Overview: ', error)
    })

    this.setActiveSurveys()

    // augment activeSurveys with participation data
    const url = ApiStats + '/surveySessions/'
    const endpoints = this.activeSurveys.map(s => url + s.slug)
    // get details of all surveys and transform them into an easily searchable object
    const survStats = (await Promise.allSettled(endpoints.map(e => { return this.fetchItem({ endpoint: e }) })))
      .reduce((obj, item) => {
        if(typeof obj !== 'undefined' && typeof item.value !== 'undefined') {
          (obj[item.value.data[0].slug] = item.value.data[0], obj)
        }
      }, {})
    if(typeof survStats !== 'undefined') {
      this.activeSurveys = this.activeSurveys.map(s => { return {...s, ...survStats[s.slug]} })
    }

    this.displaySurvey = this.activeSurveys[0]
    this.changeSurvey(this.displaySurvey)
  }
}
</script>
