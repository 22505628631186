<template>
  <div class="container">
    <b-tabs v-model="activeTab" @change="changeActiveTab">
      <b-tab-item v-for="tab in tabs" :label="labels(tab)" :key="tab">
        <b-table
          :loading="loading"
          :paginated="paginated"
          :per-page="perPage"
          :striped="true"
          :hoverable="true"
          default-sort="starts"
          :data="dataSorted[tab]"
        >
          <template slot-scope="props">
            <b-table-column :label="$getTranslation('surveys.attributes.name')" field="survey" sortable>
              <router-link
                v-if="ability.can('read', 'survinst', props.row.slug)"
                :to="{
                  name: 'surveys.detail',
                  params: { slug: props.row.slug },
                }"
              >
                {{ props.row.survey_title }}
              </router-link>
              <div v-else>{{ props.row.survey_title }}</div>
            </b-table-column>
            <b-table-column
              v-if="canSee"
              label="Organization"
              field="organization"
              sortable
            >
              {{ props.row.org_name }}
            </b-table-column>
            <b-table-column
              v-if="canSee"
              label="Division"
              field="division"
              sortable
            >
              {{ props.row.div_name }}
            </b-table-column>
            <b-table-column :label="$getTranslation('surveys.attributes.starts')" field="starts" sortable>
              {{ props.row.starts }}
            </b-table-column>
            <b-table-column :label="$getTranslation('surveys.attributes.ends')" field="ends" sortable>
              {{ props.row.expires }}
            </b-table-column>
            <b-table-column
              v-if="canSee"
              class="is-progress-col"
              label="Returns"
              field="sessions"
              sortable
            >
              <ReturnRateBar
                :sessions-finished="props.row.finished"
                :sessions-total="props.row.sessions_total"
              />
            </b-table-column>
            <b-table-column
              custom-key="actions"
              class="is-actions-cell"
              width="160"
            >
              <div class="buttons is-right">
                <router-link
                  v-if="ability.can('read', 'survinst', props.row.slug)"
                  :to="{
                    name: 'surveys.detail',
                    params: { slug: props.row.slug },
                  }"
                  class="button is-small is-primary is-inline"
                  title="Survey Details"
                >
                  <b-icon icon="arrow-right" size="is-small"></b-icon>
                </router-link>

                <!-- <a href="https://app.metriloop.de/survey"
                  v-if="isLoggedUser.roles.includes('participant')"
                  class="button is-small is-primary is-inline"
                  :title="$getTranslation('surveys.actions.participate')"
                >
                  <b-icon icon="arrow-right" size="is-small"></b-icon>
                </a> -->

                <!-- for this exact survey project, user should
                fill the personal info -->
                <router-link class="button is-small is-primary is-inline"
                  v-if="isLoggedUser.roles.includes('participant')"
                  :to="{
                    name: 'users.edit',
                    params: { slug: isLoggedUser.slug },
                  }"
                >
                  <b-icon icon="arrow-right" size="is-small"></b-icon>
                </router-link>

                <socialShare :socialTokenUrl="props.row.socialTokenUrl" />

              </div>
            </b-table-column>
          </template>

          <section class="section" slot="empty">
            <div class="content has-text-grey has-text-centered">
              <template v-if="loading">
                <p>
                  <b-icon icon="dots-horizontal" size="is-large" />
                </p>
                <p>{{ $getTranslation('general.loading') }}</p>
              </template>
              <template v-else>
                <p>
                  <b-icon icon="emoticon-sad" size="is-large" />
                </p>
                <p>{{ $getTranslation('general.0records') }}</p>
              </template>
            </div>
          </section>
        </b-table>
      </b-tab-item>
    </b-tabs>
  </div>
</template>
<script>
// import { ApiInvitations, ApiProjects, ApiAcceptInvite } from '@/api-routes.js'
import { mapActions, mapGetters } from 'vuex'
import ListTemplateMixin from '@/mixins/ListTemplateMixin'
import ReturnRateBar from './rate/ProgressBar'
import SocialShare from './SocialShare'

export default {
  name: 'SurveyList',
  mixins: [ListTemplateMixin],
  components: {
    ReturnRateBar,
    SocialShare
  },
  props: {
    tabs: Array,
    canSee: Boolean
  },
  data () {
    // console.log('items: ', this.items)
    return {
      activeTab: 0,
      testingCode: "1234",
    }
  },
  computed: {
    ...mapGetters([
      "dashboardUi",
      "surveysUi",
      "isLoggedUser",
      "ability"
    ]),

    dataSorted () {
      let res = {
        active: [],
        draft: [],
        finished: []
      }

      if(this.items !== null) {
        this.items.forEach(s => {
          if (s.survey_status) {
            res[s['survey_status']].push(s)
          }
        })
      }
      return res
    }
  },

  methods: {
    ...mapActions([
      'setUiState'
    ]),

    labels (tab) {
      return this.$t('surveys.status.' + tab)
    },

    socialInviteUrl(media, token) {
      // invitations-view
      // console.log(this.$router)
      return '/invitations/view/' + (media + token)
    },

    copyURL(link) {
      let projectLinkToCopy = document.querySelector('#project-link')
      projectLinkToCopy.setAttribute('type', 'text')
      projectLinkToCopy.select()

      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successfully' : 'unsuccessfully';
        this.$toast.success('Project link was copied ' + msg);
      } catch (err) {
        this.$toast.danger('Oops, unable to copy');
      }

      /* unselect the range */
      projectLinkToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },

    changeActiveTab(newTab) {
      this.setUiState({
        "uiName": "surveysUi",
        "mergeData": {"surveyOverview": {"openedTab": newTab}}
      })
    }
  },

  created () {
    this.activeTab = this.surveysUi.surveyOverview.openedTab
    // console.log('list created')
  }
}
</script>
