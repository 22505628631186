let colors = {
  green: 'rgba(129,199,175,0.0)', // #81C7AF // original: rgba(29,140,248,0.0)
  violetLight: 'rgba(225,78,202,0.1)', // #FF4ECA
  whiteish: '#f5f5f5',
  grey: '#9a9a9a',
  greyDark: '#333',
  greyLighter: '#666',
  red: '#FF3860',
  blue: '#209CEE',
  success: '#23d160', // (141,83,82)
  primary: '00D1B2' // rgba (164,100,77, 0)
}


export const chartColors = {
  default: {
    primary: '#00D1B2',
    info: '#209CEE',
    danger: '#FF3860',
    success: '#23d160'
  }
}

export const baseChartOptions = {
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  responsive: true
}

export let chartOptionsMain = {
  ...baseChartOptions,
  tooltips: {
    backgroundColor: colors.whiteish,
    titleFontColor: colors.greyDark,
    bodyFontColor: colors.greyLighter,
    bodySpacing: 4,
    xPadding: 12,
    mode: 'nearest',
    intersect: 0,
    position: 'nearest'
  },
  scales: {
    yAxes: [{
      barPercentage: 1.6,
      gridLines: {
        drawBorder: false,
        color: colors.green,
        zeroLineColor: 'transparent'
      },
      ticks: {
        padding: 20,
        fontColor: colors.grey
      }
    }],

    xAxes: [{
      barPercentage: 1.6,
      gridLines: {
        drawBorder: false,
        color: colors.violetLight,
        zeroLineColor: 'transparent'
      },
      ticks: {
        padding: 20,
        fontColor: colors.grey
      }
    }]
  }
}

export let chartOptionsSurveySessions = {
  ...chartOptionsMain,
  scales: {
    yAxes: [{
      barPercentage: 1.6,
      gridLines: {
        drawBorder: false,
        color: colors.green,
        zeroLineColor: 'transparent'
      },
      ticks: {
        padding: 20,
        fontColor: colors.grey,
        min: 0,
        stepSize: 1
      }
    }]
  },
  legend: {
    display: false
  }
}
