<template>
  <div>
    <b-dropdown v-if="loading">
      <button class="button is-primary" type="button" slot="trigger" style="min-width: 20rem;">
        <b-loading :active.sync="loading" :is-full-page="false" :can-cancel="true"/>
      </button>
    </b-dropdown>


    <b-dropdown
      v-else
      @change="onChangeSelectedSurvey($event)"
      v-model="localSelectedSurvey"
      aria-role="list"
      style="min-width: 20rem;">

      <button v-if="localSelectedSurvey" class="button is-primary" type="button" slot="trigger">
        <template>
          <b-icon icon="notebook-outline"/>
          <span>{{ localSelectedSurvey ? localSelectedSurvey.survey_title : "" }}</span>
        </template>
        <b-icon icon="menu-down"/>
      </button>
      <button v-else class="button is-primary" type="button" slot="trigger">
        <template>
          <b-icon icon="notebook-outline"/>
          <span>No surveys available</span>
        </template>
        <b-icon icon="menu-down"/>
      </button>

      <b-dropdown-item
        v-for="(survey, idx) in surveys"
        :key="idx"
        :value="survey"
        aria-role="listitem"
      >
        <div class="media">
          <b-icon class="media-left" icon="notebook-outline"/>
          <div class="media-content">
            <h3>{{ survey.survey_title }}</h3>
            <small>Started: {{ survey.starts_at }}</small>
          </div>
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: 'SurveysDropdown',
  props: {
    surveys: {
      type: Array, default: []
    },
    selectedSurvey: { type: Object, default: {} },
    loading: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      //
    }
  },
  computed: {
    localSelectedSurvey: {
      get: function() {
        // Mirrors the changes in the prop
        return this.selectedSurvey
      },
      set: function () {
        // setting is done by parent, this is only here to avoid errors in the console
      }
    }
  },
  methods: {
    onChangeSelectedSurvey (event) {
      // let the parent know a new survey has been selected, so it can load the data
      // this.localSelectedSurvey = event;
      this.$emit('change-selected-survey', event);
    }
  },
  created () {
    //
  }
}
</script>
