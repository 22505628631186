<template>
  <b-progress type="is-primary" show-value format="percent"
              :value="returnRate" :title="returnRate + '%'"
  ></b-progress>
</template>
<script>
export default {
  name: 'ReturnRateBar',
  props: {
    sessionsFinished: Number,
    sessionsTotal: Number
  },
  computed: {
    returnRate () {
      return this.sessionsFinished * 100 / this.sessionsTotal;
    },
  }
}
</script>
